<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回程">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要" value="1"></el-option>
					<el-option label="不需要" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="添加网点">
				<el-select class="el_input" v-model="form.outlets_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ser_para.outlets_list" :key="index" :label="item.name" :value="item.outlets_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="primary" @click="see_trajectory">查看轨迹</el-button> -->
			    <!-- <el-button type="primary" @click="Kilometers">估算距离</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="运单编号/组别编号/上游单号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">(编号) {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">(组别) {{scope.row.group1_num}}</div>
						<div class="tab_line_item">(上游) {{scope.row.out_trade_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.cargo_info.case_prov}} / {{scope.row.cargo_info.case_city}} / {{scope.row.cargo_info.case_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.cargo_info.case_addr?scope.row.cargo_info.case_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.cargo_info.aim_prov}} / {{scope.row.cargo_info.aim_city}} / {{scope.row.cargo_info.aim_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.cargo_info.aim_addr?scope.row.cargo_info.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(名称) {{scope.row.cargo_info.cargo_name}}</div>
						<div class="tab_line_item">(重量) {{scope.row.cargo_info.cargo_weight==0?"未设置":scope.row.cargo_info.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">(体积) {{scope.row.cargo_info.cargo_volume==0?"未设置":scope.row.cargo_info.cargo_volume+"方"}}</div>
						<div class="tab_line_item">(距离) {{scope.row.cargo_info.distance}} 公里</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">(总运费) {{scope.row.cost_info.freight_total}} 元</div>
						<div class="tab_line_item">(结&nbsp;&nbsp;&nbsp;算) {{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">(回&nbsp;&nbsp;&nbsp;程) {{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">(状&nbsp;&nbsp;&nbsp;态) {{scope.row.freight_payed_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名) {{scope.row.shipper_info.name}}</div>
						<div class="tab_line_item">(电话) {{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">(公司) {{scope.row.shipper_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货车信息" width="180px">
					<template slot-scope="scope">
						<!-- <div class="tab_line_item">(姓名) {{scope.row.truck_owner_info.name}}</div>
						<div class="tab_line_item">(电话) {{scope.row.truck_owner_info.tel}}</div> -->
						<div class="tab_line_item">(车牌) {{scope.row.truck_info.truck_plate_num}}</div>
						<div class="tab_line_item">(类型) {{scope.row.truck_info.truck_type_name}} / {{scope.row.truck_info.truck_type_name2}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">(姓名) {{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">(电话) {{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">(网点) {{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">(状态){{scope.row.tord_status_text}}</div>
						<div class="tab_line_item">(添加){{scope.row.tord_creat_time_text}}</div>
						<div class="tab_line_item">(发车){{scope.row.tord_start_time_text}}</div>
						<div class="tab_line_item">(到货){{scope.row.tord_arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="48">
					<template slot-scope="scope">
						<!-- <el-button @click="tord_edit(scope.row)" class="btn_left" size="mini" type="text">修改</el-button> -->
						<el-button @click="voucher_show(scope.row)" class="btn_left" size="mini" type="text" >凭证</el-button>
						<el-button @click="tord_del(scope.row)" class="btn_left" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		>
		</el-pagination>

		<!-- 运单修改界面 -->
		<!-- <tordEdit :tord_info="edit.tord_info" :is_show="edit.is_show" @sub="tord_edit_sub"></tordEdit> -->

		<!-- 凭证界面 -->
		<voucher :is_show="voucher.is_show" :voucher_info="voucher.voucher_info"></voucher>

	</div>
</template>

<script>
	// import tordEdit from './edit.vue'
	import voucher from '../voucher/voucher_after_end.vue'
	export default {
		components:{
			// tordEdit,
			voucher
		},
		data() {
			return {

				//搜索条件参数
				ser_para:{

					outlets_list:[],//网点列表
				},

				//搜索条件
				form: {
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
					voucher_info:[],
				},

				//修改相关
				edit:{
					is_show:0,
					tord_info:{}
				},
			}
		},
		created() {

			//读取网点列表
			this.get_outlets_list();

			//读取货源
			this.get_page_data()
		},
		methods: {
		    //查询公里数
			Kilometers() {
			var _this = this;;
			this.list.data.forEach((item, index) => {
				var start_cit = item.case_city
				var end_city = item.aim_city
				var start = item.case_city + item.case_county + item.case_addr;
				var end = item.aim_city + item.aim_county + item.aim_addr;
				var start_province = item.case_prov + item.case_city +item.case_county;
				var end_province = item.aim_prov+item.aim_city+item.aim_county;
				var driving = new AMap.Driving({});
				// 高德地图api 查询公里数
				driving.search(
				[
					{ keyword: start_province, city: start },
					{ keyword: end_province, city: end },
				],
				function (status, result) {
					if(start_cit == end_city && result.routes[0].distance / 1000 > 400){
					_this.list.data[index].distance = 0
					}else{
					_this.list.data[index].distance = result.routes[0].distance / 1000;
					}
				}
				);
			});
			},
			//跳转轨迹查询
			see_trajectory(){
				this.$router.push('/pages/transit_real_time/tord/maptrajectory')
			},
			//完结相关操作
			// tord_edit_sub(item){

			// 	//关闭弹出层
			// 	this.edit.is_show=0;

			// 	//刷新
			// 	this.get_page_data()
			// },
			// tord_edit(item){
			// 	this.edit.is_show++;
			// 	this.edit.tord_info=item
			// },

			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.voucher_info=item.voucher_info
			},

			//运单删除
			tord_del(item){

				//提示
				this.$my.other.confirm({
					content:"您的操作是'删除运单',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_end_del_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					outlets_num:'',//网点编号
				}
				this.get_page_data()
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				// if(this.list.loading){
				// 	return;
				// }

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_end_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{


						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//运单创建时间
							item.tord_creat_time_text=this.$my.other.totime(item.tord_creat_time);

							//运单发车时间
							if(item.tord_start_time>0){//已发车
								item.tord_start_time_text=this.$my.other.totime(item.tord_start_time);
							}else item.tord_start_time_text="未发车";

							//运单到达时间
							if(item.tord_arrived_time>0){//已到货
								item.tord_arrived_time_text=this.$my.other.totime(item.tord_arrived_time);
							}else item.tord_arrived_time_text="未到货";

							//运单结束时间
							item.tord_end_time_text=this.$my.other.totime(item.tord_end_time);

							//运单状态
							switch(item.tord_status){
								case '1':item.tord_status_text="待发车";break;
								case '2':item.tord_status_text="运输中";break;
								case '3':item.tord_status_text="已到货";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//运费支付状态
							switch(item.freight_payed_status){
								case '1':item.freight_payed_status_text="未支付";break;
								case '2':item.freight_payed_status_text="部分支付";break;
								case '3':item.freight_payed_status_text="支付完毕";break;
								default :item.freight_payed_status_text="未支付";break;
							}

							//货源详情
							item.cargo_info=JSON.parse(item.cargo_info)

							//货主详情
							item.shipper_info=JSON.parse(item.shipper_info)

							//网点信息
							item.outlets_info=JSON.parse(item.outlets_info)

							//车主详情
							item.truck_owner_info=JSON.parse(item.truck_owner_info)

							//司机数据
							item.driver_info=JSON.parse(item.driver_info)

							//货车详情
							item.truck_info=JSON.parse(item.truck_info)

							//费用信息
							item.cost_info=JSON.parse(item.cost_info)

							//凭证列表
							item.voucher_info=JSON.parse(item.voucher_info)
						}

						//渲染
						this.list.data=data.list
						this.Kilometers()
					}
				})
			},

			//读取网点列表
			get_outlets_list(){

				//调用接口
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'outlets_list_admin',
						num:1000
					},
					callback:(data)=>{

						//渲染
						this.ser_para.outlets_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>